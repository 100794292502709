import React from 'react'
import { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'

import { performRequest } from './fetch.js'

export default function Articles(props)
{
  const [data, setData] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [list, setList] = useState(null)

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.id, {children: true})
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return

    const children = data.children
    const item = (o, i) => {
      // format by priority order [link, file]
      let format = null
      if (o.article_file) format = 'file'
      if (o.article_link) format = 'link'
      return {
        name: o.name,
        title: o.title,
        year: o.article_year,
        editor: o.article_editor,
        format: format,
        link: o.article_link,
        file: o.article_file
      }
    }
    const items = map(children, item)

    setRedirect(<Redirect to={props.match.url}/>)

    // list

    const Line = (props) => {
      return (
        <>
          <div className='desktop'>
            <div className='width flex flex-align-x-left pt-h pb-h b-bottom'>
              <div className='flex-50 pr-h flex'>
                <span className='sm tabular t-nowrap flex-50 t-left'>{props.datas[0]}</span>
                <span className='sm tabular t-nowrap flex-50 t-right'>{props.datas[1]}</span>
              </div>
              <div className='flex-50 pr-h flex'>
                <span className='sm tabular t-nowrap flex-50 t-left'>{props.datas[2]}</span>
                <span className='sm tabular t-nowrap flex-50 t-right'>{props.datas[3]}</span>
              </div>
            </div>
          </div>
          <div className='mobile'>
            <div className='width flex flex-align-x-left pt-h pb-h b-bottom'>
              <span className='sm tabular t-nowrap flex-50 t-left'>{props.datas[0]}</span>
              <span className='sm tabular t-nowrap flex-50 t-right'>{props.datas[1]}</span>
            </div>
          </div>
        </>
      )
    }

    let menu = ['ANNÉE', 'TITRE', 'ÉDITEUR', 'FORMAT']
    let list =
      <div className='width height scroll b-top b-bottom'>
        <Line datas={menu}/>
        {items.map((item, i) => {
          let format
          if (item.format === 'link') format = 'url'
          if (item.format === 'file') format = item.file[0].extension
          const datas = [item.year, item.title, item.editor, format]
          let dest
          if (item.format === 'link') {
            let link = item.link
            dest =
              <a key={i} href={link} target='_blank' rel='noopener noreferrer'>
                <Line datas={datas}/>
              </a>
          }
          if (item.format === 'file') {
            let file = item.file[0]
            dest =
              <a key={i} href={file.url} target='_blank' rel='noopener noreferrer'>
                <Line datas={datas}/>
              </a>
          }
          return dest
        })}
      </div>

    setList(list)

  }, [data])

  return (
    <Switch>
      <Route exact path={props.match.path} render={() =>
        <div className='pt-h pb-h' style={{height: props.contentHeight}}>
          {list}
        </div>
      }/>
      {redirect}
    </Switch>
  )
}
