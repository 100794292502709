import React from 'react'
import { useState, useLayoutEffect, useRef } from 'react'

// RAF Hook
// https://github.com/facebook/react/issues/14195#issuecomment-437942016
// https://codesandbox.io/s/ojxl32jm4z
// TODO: lower fps
const useAnimationFrame = callback => {
  const frameRef = useRef()
  const callbackRef = useRef(callback)

  const loop = () => {
    frameRef.current = requestAnimationFrame(loop)
    const cb = callbackRef.current
    cb()
  }

  useLayoutEffect(() => {
    frameRef.current = requestAnimationFrame(loop)
    return () => cancelAnimationFrame(frameRef.current)
  }, [])

  useLayoutEffect(() => {
    callbackRef.current = callback
  }, [callback])
}

// Ticker
// hide scrollbar
// TODO autoscroll, speed, loop, apparition, keyboard navigation
// TODO : if content is smaller than container, make left offset appears on the right exactly
export default function Ticker(props)
{
  const container = useRef(null)

  let [scrolling, setScrolling] = useState(props.auto)
  let [timer, setTimer] = useState(props.auto)

  useAnimationFrame(() => {
    if (container.current && scrolling) {
      if (timer > 0) {
        setTimer(timer-1)
      } else {
        const edgy = container.current.scrollLeft >= container.current.scrollWidth/2
        container.current.scrollLeft = edgy ? container.current.scrollLeft - container.current.scrollWidth/2 : container.current.scrollLeft + props.speed
      }
    }
  })

  function enter(e) { setScrolling(false) }
  function leave(e) { setScrolling(props.auto) }
  function move(e) { setTimer(150) }

  return (
    <div
      ref={container}
      onMouseEnter={enter}
      onMouseLeave={leave}
      onMouseMove={move}
      onTouchStart={enter}
      onTouchEnd={leave}
      onTouchMove={move}
      className='width height scroll noscrollbar-y flex'
    >
      {props.children}
      {scrolling && <div className="height flex">{props.children}</div>}
    </div>
  )
}
