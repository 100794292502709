import React from 'react'
import { useState, useEffect } from 'react'
import { withRouter, Link, Route, Switch, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

import Studio from './Studio'
import Stories from './Stories'
import Projects from './Projects'
import Publications from './Publications'
import Articles from './Articles'
import { performRequest } from './fetch.js'
import './index.css'

//

// https://reactjs.org/docs/hooks-intro.html
// https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
function App(props)
{
  let location = props.location.pathname.split("/")[1]

  // initial menu state
  const menuState = () => {
    if (location === '') return false
    return true
  }

  const [size, setSize] = useState(null)
  const [data, setData] = useState(null)
  const [infos, setInfos] = useState(false)
  const [color, setColor] = useState('black')
  const [menu, setMenu] = useState(menuState)

  useEffect(() => {
    // resize
    const resize = () =>
    {
      const windowHeight = window.innerHeight

      const lineHeight = Math.floor(windowHeight / 35)
      document.body.style.fontSize = lineHeight + 'px'

      setSize({windowHeight: windowHeight, lineHeight: lineHeight})
    }

    window.addEventListener('resize', resize)
    resize()
    return function cleanup() {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.id)
        setData(response.data)
        // GA
        if (response.data.studio_googleanalytics) {
          ReactGA.initialize(response.data.studio_googleanalytics)
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (color === 'white') setColor('black')
    if (infos) setInfos(false)
    if (data && data.studio_googleanalytics) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [props.location])

  // console.log(data)
  // console.log(props)

  //

  if (!size) return null

  let sectionHeight = (size.lineHeight * 6) + 'px'
  let contentHeight = (size.windowHeight - size.lineHeight * 13) + 'px'

  // resets

  // https://stackoverflow.com/a/48637113/7662622
  const RouteReset = ({reset}) => {
    useEffect(() => {
      if (reset === 'studio') {
        if (!infos) setInfos(true)
      }
      if (reset === 'image') {
        // change header color (light box)
        if (color === 'black') setColor('white')
      }
    }, [])
    return null
  }

  // header
  // TODO source infos from backend

  let flex1, flex2
  if (infos) {
    flex1 = 'flex-0'
    flex2 = 'flex-100'
  } else {
    flex1 = 'flex-100'
    if (location === 'studio') flex1 = 'flex-0'
    if (location === 'projects') flex1 = 'flex-25'
    if (location === 'academic') flex1 = 'flex-50'
    if (location === 'press') flex1 = 'flex-75'
    flex2 = 'flex-0'
  }

  let drawer
  if (data) {
    drawer =
      <>
        <div className='flex mt-s'>
          <div className='flex-50 t-nowrap md mb-s t-left pr-h'>{data.studio_name1}</div>
          <div className='flex-50 t-nowrap md mb-s t-right ss19'>{data.studio_name2}</div>
        </div>
        <div className='flex'>
          <div className='flex-50 t-nowrap md mb-s t-left pr-h'>{data.studio_streetname}</div>
          <div className='flex-50 t-nowrap md mb-s t-right tabular'>{data.studio_streetnumber}</div>
        </div>
        <div className='flex'>
          <div className='flex-50 t-nowrap md mb-s t-left pr-h'>{data.studio_city}</div>
          <div className='flex-50 t-nowrap md mb-s t-right tabular'>{data.studio_postalcode}</div>
        </div>
        <div className='flex'>
          <div className='flex-50 t-nowrap md mb-s t-left pr-h'>T</div>
          <div className='flex-50 t-nowrap md mb-s t-right tabular'><a href={"tel:" + data.studio_phonenumber}>{data.studio_phonenumber}</a></div>
        </div>
        <div className='flex'>
          <div className='flex-50 t-nowrap md t-left pr-h'>E</div>
          <div className='flex-50 t-nowrap md t-right'><a href={"mailto:" + data.studio_email}>{data.studio_email}</a></div>
        </div>
      </>
  }

  // nav
  // TODO get nav from home page reference field
  let nav =
    <div className='flex flex-row flex-wrap flex-align-x-left flex-align-y-bottom width'>
      <div className='flex-25 flex-m md'><Link to='/studio'>STUDIO</Link></div>
      <div className='flex-25 flex-m md'><Link to='/projects'>PROJETS</Link></div>
      <div className='flex-25 flex-m md'><Link to='/academic'>ACADEMIQUE</Link></div>
      <div className='flex-25 flex-m md'><Link to='/press'>PRESSE</Link></div>
    </div>
  let navPathes = ["/", "/projects", "/academic", "/press"]
  let navRoutes =
    navPathes.map((path, i) => (
      <Route key={i} exact path={path} render={() =>
        <div className='flex flex-row flex-align-x-left width z-front relative' style={{height: sectionHeight}}>
          {nav}
        </div>
      }/>
    ))

  let textColor = color === 'white' ? ' t-white' : ''

  let helmet
  if (data) {
    helmet =
    <Helmet>
        <title>{data.meta_title}</title>
        <meta name="description" content={data.meta_description} />
        <meta property="og:title" content={data.meta_title} />
        <meta property="og:description" content={data.meta_description} />
        <meta property="og:image" content={process.env.REACT_APP_URL+'favicon.png'} />
        <meta property="og:url" content={process.env.REACT_APP_URL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content={data.meta_title} />
        <meta name="twitter:image:alt" content={data.meta_title} />
      </Helmet>
  }

  return (
    <>
      {helmet}

      <div className='p-h'>

        <Route exact path='/studio' render={(props) => <RouteReset reset='studio'/>}/>
        <Route path='/projects/:project/images/:id' render={(props) => <RouteReset reset='image'/>}/>

        <div className={'flex flex-row flex-align-x-left width' + textColor} style={{height: sectionHeight}}>
          <div className={'height flex flex-align-y-center transition-flex ' + flex1}>
            <span className='bg ss15 noselect'><Link to='/' onClick={() => setMenu(false)}>D</Link></span>
          </div>
          <div className='height flex flex-align-y-center transition-flex'>
            <span
              style={{ paddingLeft: '.05em' }}
              className='bg ss15 noselect repaint'
            >
              A
            </span>
          </div>
          <Route path='/studio' render={(props) =>
            <div className={'desktop height pl-h hidden transition-flex ' + flex2}>{drawer}</div>
          }/>
        </div>

        <Switch>
          <Route exact path='/' render={(props) =>
            <Stories {...props} id='/news' contentHeight={contentHeight} sectionHeight={sectionHeight} setMenu={setMenu} menu={menu}/>
          }/>
          <Route path='/studio' render={(props) =>
            <Studio {...props} id='/studio' contentHeight={contentHeight} sectionHeight={sectionHeight} drawer={drawer}/>
          }/>
          <Route path='/projects' render={(props) =>
            <Projects {...props} id='/projects' contentHeight={contentHeight} sectionHeight={sectionHeight}/>
          }/>
          <Route path='/academic' render={(props) =>
            <Publications {...props} id='/academic' contentHeight={contentHeight} sectionHeight={sectionHeight}/>
          }/>
          <Route path='/press' render={(props) =>
            <Articles {...props} id='/press' contentHeight={contentHeight}/>
          }/>
          <Redirect from='/:somethingelse' to='/'/>
        </Switch>

        {menu && navRoutes}

      </div>
    </>
  )
}

export default withRouter(App)
