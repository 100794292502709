import React from 'react'
import { useState, useEffect } from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'

import { performRequest } from './fetch.js'
import Ticker from './Ticker'

export default function Publication(props)
{
  const [data, setData] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [content, setContent] = useState(null)
  const [section, setSection] = useState(null)

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.match.url)
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  let Menu = (props) => <div className={'absolute top right p-h ' + props.color}>{props.children}</div>
  let linkCloseItem = <Link to='/academic'><span className='md ss16 noselect pointer'>FERMER</span></Link>

  useEffect(() => {
    if (!data) return

    setRedirect(<Redirect to={`${props.match.url}`}/>)

    // content

    let article = (o, i) => {
      let image = o.fields.publication_image
      let url, description
      if (image) {
        let img = o.fields.publication_image[0]
        url = img['bp']['1200']['httpUrl']
        description = img.description ? img.description : img.name
      }
      let text = o.fields.publication_text.formatted
      return (
        <div key={i} className='width flex flex-align-x-left pb-1' style={{minHeight: '100%'}}>
          <div className='flex-50 desktop'>
            <div className='width pr-h sticky' style={{top: '0'}}>
              {image && <img alt={description} className='block' style={{maxWidth: '60%', maxHeight: props.contentHeight}} src={url}/>}
            </div>
          </div>
          <div className='flex-50 flex-m'>
            {image && <img alt={description} className='block mobile pb-1' style={{maxWidth: '100%', maxHeight: props.contentHeight}} src={url}/>}
            {text && <div className='editor' dangerouslySetInnerHTML={{__html: text}}/>}
          </div>
        </div>
      )
    }
    let articles = map(data.publication_article, article)

    setContent(
      <div className='width height pt-h pb-h'>
        <div className='width height scroll noscrollbar-x'>
          {articles}
        </div>
      </div>
    )

    // section

    setSection(
      <Ticker auto speed={1}>
        <div className='width height flex'>
          <div className='height flex-25 flex-noshrink white'></div>
          <div className='bg t-nowrap'>{data.title.toUpperCase()}</div>
          <div className='height flex-100 flex-noshrink white'></div>
        </div>
      </Ticker>
    )

  }, [data])

  return (
    <Switch>
      <Route exact path={`${props.match.url}`} render={() =>
        <>
          <Menu>{linkCloseItem}</Menu>
          <div className='width' style={{height: props.contentHeight}}>
            {content}
          </div>
          <div className='width' style={{height: props.sectionHeight}}>
            {section}
          </div>
        </>
      }/>
      {redirect}
    </Switch>
  )
}
