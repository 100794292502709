import React from 'react'
import { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'
import head from 'lodash/head'

import { performRequest } from './fetch.js'
import Ticker from './Ticker'

export default function Stories(props)
{
  const [data, setData] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [images, setImages] = useState(null)
  const [section, setSection] = useState(null)

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.id)
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return

    const news = data.stories_news
    const image = (i) => { return i['bp']['1200']['httpUrl'] }
    const item = (o, i) => (
      {
        text: o.fields.stories_text,
        link: o.fields.stories_link,
        image: image(head(o.fields.stories_image))
      }
    )
    const items = map(news, item)
    // console.log(items)

    // set redirect only after datas are loaded in order to prevent /:child from being redirected
    setRedirect(<Redirect to={props.match.url}/>)

    // images

    const Thumbnail = ({item, style}) => {
      let [infos, setInfos] = useState(false)
      function enter() { if (!infos) setInfos(true) }
      function leave() { if (infos) setInfos(false) }
      return (
        <div onMouseEnter={enter} onMouseLeave={leave} className={'relative height' + style}>
          <a href={item.link} target='_blank' rel='noopener noreferrer'>
            <img alt={item.title} className='block height' src={item.image}/>
          </a>
        </div>
      )
    }

    let images =
      <Ticker auto speed={2}>
        <div className='height flex'>
          <div className='height white' style={{width: '50vw'}}/>
          {items.map((item, i) =>
            <Thumbnail key={i} item={item} style={(i < items.length-1) ? ' mr-h' : ''}/>
          )}
        </div>
      </Ticker>

    setImages(images)

    // section

    setSection(
      <Ticker auto speed={1}>
        <div className='height flex'>
          {items.map((item, i) =>
            <a key={i} href={item.link} target='_blank' rel='noopener noreferrer'>
              <div className='bg t-nowrap'>{item.text.toUpperCase()}</div>
            </a>
          )}
        </div>
      </Ticker>
    )

  }, [data])

  return (
    <Switch>
      <Route exact path={props.match.path} render={() =>
        <>
          <div className='pt-h pb-h' style={{height: props.contentHeight}}>
            {images}
          </div>
          {!props.menu &&
            <div className='width' style={{height: props.sectionHeight}}>
              <div className='height flex flex-align-y-center'>
                <div className='height z-front'><span className='bg ss16 noselect pointer' onClick={() => props.setMenu(true)}>INDEX</span></div>
                <div className='height flex-100 hidden pl-h'>{section}</div>
              </div>
            </div>
          }
        </>
      }/>
      {redirect}
    </Switch>
  )
}
