import React from 'react'
import { useState, useEffect } from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'
import head from 'lodash/head'

import { performRequest } from './fetch.js'
import Ticker from './Ticker'
import Publication from './Publication'

export default function Publications(props)
{
  const [data, setData] = useState(null)
  const [children, setChildren] = useState([])
  const [redirect, setRedirect] = useState(null)
  const [images, setImages] = useState(null)
  const [list, setList] = useState(null)
  const [mode, setMode] = useState('images')

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.id, {children: true})
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return

    const children = data.children
    let articles = []
    const image = (i) => { return i['bp']['1200']['httpUrl'] }
    const item = (o, i) => {
      // format by priority order [link, article, file]
      let format = null
      if (o.publication_file) format = 'file'
      if (o.publication_article) {
        format = 'article'
        articles.push(o.name)
      }
      if (o.publication_link) format = 'link'
      return {
        name: o.name,
        title: o.title,
        year: o.publication_year,
        academy: o.publication_academy,
        image: image(head(o.publication_thumbnail)),
        format: format,
        link: o.publication_link,
        file: o.publication_file
      }
    }
    const items = map(children, item)
    // console.log(items)

    // special redirect > react router only renders route if it's existing among children
    // only 'article' publications have a route
    setChildren(articles)
    // set redirect only after datas are loaded in order to prevent /:child from being redirected
    setRedirect(<Redirect to={props.match.url}/>)

    // images

    const Thumbnail = ({item, style}) => {
      let [infos, setInfos] = useState(false)
      function enter() { if (!infos) setInfos(true) }
      function leave() { if (infos) setInfos(false) }
      // console.log(item.format)
      let dest
      if (item.format === 'link') {
        let link = item.link
        dest =
          <a href={link} target='_blank' rel='noopener noreferrer'>
            <img alt={item.title} className='block height' src={item.image}/>
          </a>
      }
      if (item.format === 'article') {
        dest =
          <Link to={`${props.match.url}/${item.name}`}>
            <img alt={item.title} className='block height' src={item.image}/>
          </Link>
      }
      if (item.format === 'file') {
        let file = item.file[0]
        dest =
          <a href={file.url} target='_blank' rel='noopener noreferrer'>
            <img alt={item.title} className='block height' src={item.image}/>
          </a>
      }
      let description
      if (infos) {
        description =
          <div className='relative flex width' style={{height: 0, bottom: '-.5em', left: 0}}>
            <div className='absolute flex-75 t-left' style={{height: 0}}>{item.title.toUpperCase()}</div>
          </div>
      }
      return (
        <div onMouseEnter={enter} onMouseLeave={leave} className={'relative height' + style}>
          {dest}
          {description}
        </div>
      )
    }

    let images =
      <Ticker auto speed={2}>
        <div className='height flex'>
          <div className='height white' style={{width: '50vw'}}/>
          {items.map((item, i) =>
            <Thumbnail key={i} item={item} style={(i < items.length-1) ? ' mr-h' : ''}/>
          )}
        </div>
      </Ticker>

    setImages(images)

    // list

    const Line = (props) => {
      return (
        <>
          <div className='desktop'>
            <div className='width flex flex-align-x-left pt-h pb-h b-bottom'>
              <div className='flex-50 pr-h flex'>
                <span className='sm tabular t-nowrap flex-50 t-left'>{props.datas[0]}</span>
                <span className='sm tabular t-nowrap flex-50 t-right'>{props.datas[1]}</span>
              </div>
              <div className='flex-50 pr-h flex'>
                <span className='sm tabular t-nowrap flex-50 t-left'>{props.datas[2]}</span>
                <span className='sm tabular t-nowrap flex-50 t-right'>{props.datas[3]}</span>
              </div>
            </div>
          </div>
          <div className='mobile'>
            <div className='width flex flex-align-x-left pt-h pb-h b-bottom'>
              <span className='sm tabular t-nowrap flex-50 t-left'>{props.datas[0]}</span>
              <span className='sm tabular t-nowrap flex-50 t-right'>{props.datas[1]}</span>
            </div>
          </div>
        </>
      )
    }

    // TODO source infos from backend
    let menu = ['ANNÉE', 'PROJET', 'ECOLE', 'FORMAT']
    let list =
      <div className='width height scroll b-top b-bottom'>
        <Line datas={menu}/>
        {items.map((item, i) => {
          let format
          if (item.format === 'link') format = 'url'
          if (item.format === 'article') format = 'html'
          if (item.format === 'file') format = item.file[0].extension
          const datas = [item.year, item.title, item.academy, format]
          let dest
          if (item.format === 'link') {
            let link = item.link
            dest =
              <a key={i} href={link} target='_blank' rel='noopener noreferrer'>
                <Line datas={datas}/>
              </a>
          }
          if (item.format === 'article') {
            dest =
              <Link key={i} to={`${props.match.url}/${item.name}`}>
                <Line datas={datas}/>
              </Link>
          }
          if (item.format === 'file') {
            let file = item.file[0]
            dest =
              <a key={i} href={file.url} target='_blank' rel='noopener noreferrer'>
                <Line datas={datas}/>
              </a>
          }
          return dest
        })}
      </div>

    setList(list)

  }, [data])

  let selection =
    <div className='absolute top right p-h'>
      <span className='md ss16 noselect pointer' style={{marginRight: '.2em'}} onClick={() => setMode('images')}>IMAGES</span>
      <span className='md ss16 noselect pointer' onClick={() => setMode('list')}>LISTE</span>
    </div>

  let markup = (mode === 'images') ? images : list

  let contentHeight = props.contentHeight
  let sectionHeight = props.sectionHeight

  let routes =
    children.map((child, i) => (
      <Route key={i} strict path={props.match.url + '/' + child} render={(props) =>
        <Publication {...props} contentHeight={contentHeight} sectionHeight={sectionHeight}/>
      }/>
    ))

  return (
    <Switch>
      <Route exact path={props.match.path} render={() =>
        <div className='pt-h pb-h' style={{height: props.contentHeight}}>
          {selection}
          {markup}
        </div>
      }/>
      {routes}
      {redirect}
    </Switch>
  )
}
