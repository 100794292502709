import axios from 'axios'

export function performRequest(method, url, params)
{
  params = params || {}

  const config = {
    method: method,
    baseURL: process.env.REACT_APP_API_URL,
    url: url,
    params: params
  }

  return axios.request(config)
}
