import React from 'react'
import { useState, useEffect } from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'

import { slugify } from './utils.js'
import { performRequest } from './fetch.js'
import Ticker from './Ticker'

export default function Project(props)
{
  const [data, setData] = useState(null)
  const [routes, setRoutes] = useState([])
  const [redirect, setRedirect] = useState(null)
  const [imagesTicker, setImagesTicker] = useState(null)
  const [titleTicker, setTitleTicker] = useState(null)
  const [infos, setInfos] = useState(null)

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.match.url)
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  let Menu = (props) => <div className={'absolute top right p-h ' + props.color}>{props.children}</div>
  let linkInfos = <Link to={`${props.match.url}/infos`}><span className='md ss16 noselect pointer' style={{marginRight: '.2em'}}>INFOS</span></Link>
  let linkImages = <Link to={`${props.match.url}/images`}><span className='md ss16 noselect pointer' style={{marginRight: '.2em'}}>IMAGES</span></Link>
  let linkCloseProject = <Link to='/projects'><span className='md ss16 noselect pointer'>FERMER</span></Link>
  let linkCloseImage = <Link to={`${props.match.url}/images`}><span className='md ss16 noselect pointer'>FERMER</span></Link>

  useEffect(() => {
    if (!data) return

    // images

    const image = (o, i) => (
      {
        url: o['bp']['1200']['httpUrl'],
        origin: o['origin']['httpUrl'],
        description: o['origin']['description'],
      }
    )
    const images = map(data.project_images, image)

    const Thumbnail = ({id, image, style}) => {
      let url = image.description ? slugify(image.description) : id+1
      return (
        <div className={'relative height' + style}>
          <Link to={`${props.match.url}/images/${url}`}>
            <img alt={image.description} className='block height' src={image.url}/>
          </Link>
        </div>
      )
    }

    setImagesTicker(
      <Ticker auto speed={2}>
        <div className='height flex'>
          <div className='height white' style={{width: '25vw'}}/>
          {images.map((image, i) =>
            <Thumbnail key={i} id={i} image={image} style={(i < images.length-1) ? ' mr-h' : ''}/>
          )}
        </div>
      </Ticker>
    )

    let routes = images.map((image, id) => {
      let url = image.description ? slugify(image.description) : id+1
      let previousId = (images.length+id-1) % images.length
      let previousUrl = images[previousId].description ? slugify(images[previousId].description) : previousId+1
      let previous = <Link to={`${props.match.url}/images/${previousUrl}`}><div className='absolute top left height pointer-l' style={{width: '50%'}}/></Link>
      let nextId = (images.length+id+1) % images.length
      let nextUrl = images[nextId].description ? slugify(images[nextId].description) : nextId+1
      let next = <Link to={`${props.match.url}/images/${nextUrl}`}><div className='absolute top right height pointer-r' style={{width: '50%'}}/></Link>
      return (
        <Route key={id} strict path={`${props.match.url}/images/${url}`} render={() =>
          <>
            <Menu color=' t-white'>{linkInfos}{linkCloseImage}</Menu>
            <div className='absolute top left z-back fullscreen black' style={{padding: '0.57em 0.5em 0.47em 0.5em'}}>
              <div className='width height back-center back-norepeat back-contain' style={{backgroundImage: `url(${image.origin})`}}/>
              <div className='absolute bottom left p-h t-white desktop'><p className='md'>{data.title.toUpperCase()}</p></div>
              <div className='absolute bottom right p-h t-white'><p className='md'>{image.description.toUpperCase()}</p></div>
              {previous}
              {next}
            </div>
          </>
        }/>
    )})

    setRoutes(routes)
    setRedirect(<Redirect to={`${props.match.url}/images`}/>)

    // title

    setTitleTicker(
      <Ticker auto speed={1}>
        <div className='height flex'>
          <div className='height white' style={{width: '25vw'}}/>
          <div className='height bg t-nowrap'>{data.title.toUpperCase()}</div>
        </div>
      </Ticker>
    )

    // infos
    // TODO : allow no info in the backend

    let country = (data.project_location) ? data.project_location.fields.projects_country : ''
    let city = (data.project_location) ? data.project_location.fields.projects_city : ''
    let type = (data.project_type) ? data.project_type.fields.projects_type : ''
    let status = (data.project_status) ? data.project_status.fields.projects_status : ''

    let datas = [
      { category: 'ANNÉE', data: data.project_year },
      { category: 'N°', data: data.project_id },
      { category: 'PROJET', data: data.title },
      { category: 'LIEU', data: city },
      { category: 'PAYS', data: country },
      { category: 'CLIENT', data: data.project_client },
      { category: 'M2', data: data.project_area },
      { category: 'TYPE', data: type },
      { category: 'STATUT', data: status },
      { category: 'ÉQUIPE', data: data.project_team },
      { category: 'PHOTOGRAPHE', data: data.project_photographer }
    ]

    let columns = datas.map((line, i) => {
      if (line.data) {
        return (
          <div key={i} className='width flex flex-align-x-left'>
            <div className='flex-50 sm pr-h'>
              {line.category}
            </div>
            <div className='flex-50 sm'>
              {line.data}
            </div>
          </div>
        )
      } else {
        return null
      }
    })

    setInfos(
      <div className='flex flex-align-x-left flex-wrap width height scroll noscrollbar-x'>
        <div className='flex-50 flex-m pb-h pr-h'>
          {columns}
        </div>
        <div className='flex-50 flex-m pb-h'>
          <div className='editor' dangerouslySetInnerHTML={{__html: data.project_description.formatted}}/>
        </div>
      </div>
    )

  }, [data])

  return (
    <>
      <Switch>
        <Route exact path={`${props.match.url}/infos`} render={() =>
          <>
            <Menu>{linkImages}{linkCloseProject}</Menu>
            <div className='pt-h pb-h' style={{height: props.contentHeight}}>
              {infos}
            </div>
          </>
        }/>
        <Route exact path={`${props.match.url}/images`} render={() =>
          <>
            <Menu>{linkInfos}{linkCloseProject}</Menu>
            <div className='pt-h pb-h' style={{height: props.contentHeight}}>
              {imagesTicker}
            </div>
          </>
        }/>
        {routes}
        {redirect}
      </Switch>
      <Switch>
        <Route exact path={`${props.match.url}/images/:id`}/>
        <Route path={`${props.match.url}/`} render={() =>
          <div className='width' style={{height: props.sectionHeight}}>
            {titleTicker}
          </div>
        }/>
      </Switch>
    </>
  )
}
