import React from 'react'
import { useState, useEffect } from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'

import { performRequest } from './fetch.js'
import Ticker from './Ticker'

export default function Studio(props)
{
  const [data, setData] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [content, setContent] = useState(null)
  const [section, setSection] = useState(null)

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.id)
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return

    setRedirect(<Redirect to={`${props.match.url}`}/>)

    // content

    let section = (o, i) => {
      let image = o.fields.studio_image
      let url, description
      if (image) {
        let img = image[0]
        url = img['bp']['1200']['httpUrl']
        description = img.description ? img.description : img.name
      }
      let text = o.fields.studio_text.formatted
      let text1 = o.fields.studio_text1
      let text2 = o.fields.studio_text2
      let indented1 = o.fields.studio_indented1
      let indented2 = o.fields.studio_indented2
      let columns
      if (text1 || text2 || indented1 || indented2) {
        columns =
          <div className="flex flex-align-x-left flex-wrap">
            <div className="flex-50 flex-m pb-1">
              {text1 && <div className='editor' dangerouslySetInnerHTML={{__html: text1.formatted}}/>}
              {indented1 && indented1.map((o, i) =>
                <div key={i} className='flex flex-align-x-left flex-align-y-top editor'>
                  <span className='flex-20 tabular'>
                    {o.fields.studio_indentedleft}
                  </span>
                  <span className='flex-80 pl-h pr-h' dangerouslySetInnerHTML={{__html: o.fields.studio_indentedright.formatted}}/>
                </div>
              )}
            </div>
            <div className="flex-50 flex-m pb-1">
              {text2 && <div className='editor' dangerouslySetInnerHTML={{__html: text2.formatted}}/>}
              {indented2 && indented2.map((o, i) =>
                <div key={i} className='flex flex-align-x-left flex-align-y-top editor'>
                  <span className='flex-20 tabular'>
                    {o.fields.studio_indentedleft}
                  </span>
                  <span className='flex-80 pl-h pr-h' dangerouslySetInnerHTML={{__html: o.fields.studio_indentedright.formatted}}/>
                </div>
              )}
            </div>
          </div>
      }

      return (
        <div key={i} className='width flex flex-align-x-left pb-1' style={{minHeight: '100%'}}>
          <div className='flex-50 desktop'>
            <div className='width pr-h sticky' style={{top: '0'}}>
              {image && <img alt={description} className='block' style={{maxWidth: '60%', maxHeight: props.contentHeight}} src={url}/>}
            </div>
          </div>
          <div className='flex-50 flex-m'>
            {image && <img alt={description} className='block mobile pb-1' style={{maxWidth: '100%', maxHeight: props.contentHeight}} src={url}/>}
            {text && <div className='editor' dangerouslySetInnerHTML={{__html: text}}/>}
            {columns}
          </div>
        </div>
      )
    }
    let sections = map(data.studio_sections, section)

    setContent(
      <div className='width height pt-h pb-h'>
        <div className='width height scroll noscrollbar-x'>
          <div className='mobile width pb-h'>{props.drawer}</div>
          {sections}
        </div>
      </div>
    )

    // section

    setSection(
      <Ticker auto speed={1}>
        <div className='height flex'>
          <div className='height white' style={{width: '35vw'}}/>
          <div className='bg t-nowrap'>{data.title.toUpperCase()}</div>
        </div>
      </Ticker>
    )

  }, [data])

  return (
    <Switch>
      <Route exact path={`${props.match.url}`} render={() =>
        <>
          <div className='width' style={{height: props.contentHeight}}>
            {content}
          </div>
          <div className='width' style={{height: props.sectionHeight}}>
            <div className='height flex flex-align-y-center'>
              <div className='height'><Link to='/'><span className='bg ss16 noselect pointer'>FERMER</span></Link></div>
              <div className='height flex-100 hidden pl-h'>{section}</div>
            </div>
          </div>
        </>
      }/>
      {redirect}
    </Switch>
  )
}
