import React from 'react'
import { useState, useEffect } from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import map from 'lodash/map'
import head from 'lodash/head'
import sortBy from 'lodash/sortBy'

import { performRequest } from './fetch.js'
import Ticker from './Ticker'
import Project from './Project'

export default function Projects(props)
{
  const [data, setData] = useState(null)
  const [children, setChildren] = useState([])
  const [redirect, setRedirect] = useState(null)
  const [images, setImages] = useState(null)
  const [list, setList] = useState(null)
  const [mode, setMode] = useState('images')
  const [sort, setSort] = useState('id')
  const [desc, setDesc] = useState(true)
  // const [loaded, setLoaded] = useState(0)

  useEffect(() => {
    // fetch
    const fetchData = async () => {
      try {
        let response = await performRequest('get', props.id, {children: true})
        setData(response.data)
        // console.log(response.data)
      } catch(err) {
        // console.log(err)
      }
    }

    fetchData()
    // console.log('fetch projects')
  }, [])

  // let count = 0
  // let total = 0
  // function onload(e)
  // {
  //   if (e.target.complete) count++
  //   if (count >= total) setLoaded(1)
  //   // console.log(count + ' loaded')
  // }
  // <img onLoad={onload} alt={project.title} className='block height pr-h' src={project.image}/>
  // total = children.length

  const click = (s) => {
    if (s === sort) setDesc(!desc)
    else setDesc(false)
    setSort(s)
  }

  useEffect(() => {
    if (!data) return

    const children = data.children
    const image = (i) => { return i['bp']['800']['httpUrl'] }
    const project = (o, i) => {
      let country = (o.project_location) ? o.project_location.fields.projects_country : ''
      let city = (o.project_location) ? o.project_location.fields.projects_city : ''
      let type = (o.project_type) ? o.project_type.fields.projects_type : ''
      let status = (o.project_status) ? o.project_status.fields.projects_status : ''
      return {
        name: o.name,
        year: o.project_year,
        id: o.project_id,
        title: o.title,
        city: city,
        country: country,
        area: o.project_area,
        type: type,
        status: status,
        image: image(head(o.project_images))
      }
    }
    let projects = map(children, project)
    projects = sortBy(projects, sort)
    if (desc) projects.reverse()
    // console.log(projects)

    // special redirect > react router only renders route if it's existing among children
    setChildren(projects.map((project) => project.name))
    // set redirect only after datas are loaded in order to prevent /:child from being redirected
    setRedirect(<Redirect to={props.match.url}/>)

    // images

    const Thumbnail = ({project, style}) => {
      let [infos, setInfos] = useState(false)
      function enter() { if (!infos) setInfos(true) }
      function leave() { if (infos) setInfos(false) }
      let description
      if (infos) {
        description =
          <div className='desktop'>
            <div className='absolute flex width' style={{bottom: '-1.5em', left: 0}}>
              <div className='flex-25 t-left tabular'>{project.id}</div>
              <div className='flex-75 t-right t-nowrap t-ellipsis'>{project.title.toUpperCase()}</div>
            </div>
          </div>
      }
      return (
        <div onMouseEnter={enter} onMouseLeave={leave} className={'relative height' + style}>
          <Link to={`${props.match.url}/${project.name}`}>
            <img alt={project.title} className='block height' src={project.image}/>
          </Link>
          {description}
        </div>
      )
    }

    let images =
      <Ticker auto speed={2}>
        <div className='height flex'>
          <div className='height white block' style={{minWidth: '25vw'}}/>
          {projects.map((project, i) =>
            <Thumbnail key={i} project={project} style={(i < projects.length-1) ? ' mr-h' : ''}/>
          )}
        </div>
      </Ticker>

    setImages(images)

    // list

    const Line = (props) => {
      let Cell
      if (props.sortKeys)
        Cell = (props) =>
          <div
            className={'flex-50 flex-noshrink sm tabular t-nowrap t-ellipsis pointer noselect' + (props.right ? ' t-right' : ' t-left')}
            onClick={() => click(props.sort)}
          >
            {props.text}
          </div>
      else
        Cell = (props) =>
          <div className={'flex-50 flex-noshrink sm tabular t-nowrap t-ellipsis' + (props.right ? ' t-right' : ' t-left')}>{props.text}</div>

      return (
        <>
          <div className='desktop'>
            <div className='width flex flex-align-x-left pt-h pb-h b-bottom'>
              <div className='flex-25 flex-noshrink flex pr-h hidden'>
                <Cell sort={props.sortKeys && props.sortKeys[0]} text={props.datas[0]} left/>
                <Cell sort={props.sortKeys && props.sortKeys[1]} text={props.datas[1]} right/>
              </div>
              <div className='flex-25 flex-noshrink flex pr-h hidden'>
                <Cell sort={props.sortKeys && props.sortKeys[2]} text={props.datas[2]} left/>
                <Cell sort={props.sortKeys && props.sortKeys[3]} text={props.datas[3]} right/>
              </div>
              <div className='flex-25 flex-noshrink flex pr-h hidden'>
                <Cell sort={props.sortKeys && props.sortKeys[4]} text={props.datas[4]} left/>
                <Cell sort={props.sortKeys && props.sortKeys[5]} text={props.datas[5]} right/>
              </div>
              <div className='flex-25 flex-noshrink flex pr-h hidden'>
                <Cell sort={props.sortKeys && props.sortKeys[6]} text={props.datas[6]} left/>
                <Cell sort={props.sortKeys && props.sortKeys[7]} text={props.datas[7]} right/>
              </div>
            </div>
          </div>
          <div className='mobile'>
            <div className='width flex flex-align-x-left pt-h pb-h b-bottom'>
              <Cell sort={props.sortKeys && props.sortKeys[0]} text={props.datas[0]} left/>
              <Cell sort={props.sortKeys && props.sortKeys[2]} text={props.datas[2]} right/>
            </div>
          </div>
        </>
      )
    }

    // TODO source infos from backend
    let menu = ['ANNÉE', 'N°', 'PROJET', 'LIEU', 'PAYS', 'M2', 'TYPE', 'STATUT']
    let keys = ['year', 'id', 'title', 'city', 'country', 'area', 'type', 'status']
    let list =
      <div className='width height scroll b-top b-bottom'>
        <Line sortKeys={keys} datas={menu}/>
        {projects.map((project, i) => {
          const datas = [project.year, project.id, project.title, project.city, project.country, project.area, project.type, project.status]
          return (
            <Link key={i} to={`${props.match.url}/${project.name}`}>
              <Line datas={datas}/>
            </Link>
          )
        })}
      </div>

    setList(list)

  }, [data, sort, desc])

  let selection =
    <div className='absolute top right p-h'>
      <span className='md ss16 noselect pointer' style={{marginRight: '.2em'}} onClick={() => setMode('images')}>IMAGES</span>
      <span className='md ss16 noselect pointer' onClick={() => setMode('list')}>LISTE</span>
    </div>

  let markup = (mode === 'images') ? images : list

  let contentHeight = props.contentHeight
  let sectionHeight = props.sectionHeight

  // projects routes
  // https://github.com/ReactTraining/react-router/issues/4551#issuecomment-345352848
  // https://reacttraining.com/react-router/web/example/url-params
  let routes =
    children.map((child, i) => (
      <Route key={i} strict path={props.match.url + '/' + child} render={(props) =>
        <Project {...props} contentHeight={contentHeight} sectionHeight={sectionHeight}/>
      }/>
    ))

  return (
    <Switch>
      <Route exact path={props.match.path} render={() =>
        <div className='pt-h pb-h' style={{height: props.contentHeight}}>
          {selection}
          {markup}
        </div>
      }/>
      {routes}
      {redirect}
    </Switch>
  )
}
