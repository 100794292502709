import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'

console.log('~')
console.log('design: Tancrède Ottiger (http://t--o.ch/)')
console.log('code: Karian Før (https://subtext.studio/)')
console.log('~')

ReactDOM.render(
  <Router basename={process.env.REACT_BASE_PATH}>
    <App id='1'/>
  </Router>,
  document.getElementById('root')
)
